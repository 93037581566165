<template>
  <div class="build-container">
    <template v-for="(item, index) in items">
      <build-item :key="index" :item="item" v-on="$listeners" isAdmin />
    </template>
  </div>
</template>

<script>
import BuildItem from "./ItemBuild";

export default {
  props: {
    items: {
      type: Array,
      require: true,
      default: () => []
    }
  },
  components: {
    BuildItem
  }
};
</script>
