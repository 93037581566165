<template>
  <div class="build-item d-flex rounded-sm bg-white">
    <section
      class="build-status-column d-flex justify-content-center align-items-center rounded-left py-3"
      :style="{ 'background-color': `var(${statusColor})` }"
    >
      <font-awesome-icon :icon="statusIcon" :style="{ color: 'white' }" />
    </section>
    <section class="container-fluid px-0 mx-3 py-2">
      <div class="row no-gutters py-2 border-bottom">
        <div class="col-12 col-sm-9">
          <div class="row no-gutters align-items-center">
            <div
              class="col-6 col-sm-2 font-weight-bolder text-capitalize text-lg"
              :style="{ color: `var(${statusColor})` }"
            >
              {{ statusName }}
            </div>

            <div class="col-6 col-sm-3 text-sm font-weight-bolder text-lg">
              <span>Build</span>
              <font-awesome-icon :icon="['fas', 'hashtag']" class="ml-2" />
              <span class="ml-1">
                {{ item.build_number }}
              </span>
            </div>
            <div
              class="col-12 col-sm-auto mt-1 mt-sm-0 d-flex align-items-center"
            >
              <font-awesome-icon
                :icon="['fas', 'code-branch']"
                :style="{ color: 'rgba(0, 0, 0, 0.60)' }"
              />
              <span
                class="ml-2 text-center text-white font-weight-bolder py-1 px-2 rounded-sm mn-badge"
                :style="{ 'background-color': `var(${branchColor})` }"
              >
                {{ item.branch }}
              </span>
            </div>
            <div class="col-12 col-sm mt-3 mt-sm-0 d-flex align-items-cente">
              <icon-workflow
                fillColor="rgba(0, 0, 0, 0.60)"
                :size="24"
                class="ml-sm-2"
              />
              <span
                class="ml-2 font-weight-bolder py-1 px-2 rounded-sm border mn-badge"
              >
                {{ item.triggered_workflow }}
              </span>
            </div>
          </div>
        </div>
        <div
          class="col-12 order-first col-sm order-sm-last d-flex justify-content-end pr-2"
        >
          <div class="d-flex align-items-center">
            <b-form-checkbox
              v-if="isAdmin"
              switch
              v-model="item.actived"
              @change="onActived($event, item.slug)"
            />
            <b-button
              v-b-tooltip.hover
              title="View Artifacts"
              variant="link"
              size="sm"
              @click="$emit('push-route', item.slug)"
            >
              <font-awesome-icon :icon="['far', 'eye']" size="lg" />
            </b-button>
            <b-button
              v-if="isAdmin"
              v-b-tooltip.hover
              title="Save"
              variant="link"
              size="sm"
              @click.stop="onUpdate()"
            >
              <font-awesome-icon :icon="['far', 'save']" size="lg" />
            </b-button>
          </div>
        </div>
      </div>
      <div class="row no-gutters py-1">
        <div class="col-12 col-sm-6">
          <div class="row no-gutters">
            <div class="col-7 col-sm text-lg">
              <span>
                {{ item.finished_at | formatDate }}
              </span>
              <span class="ml-2">
                {{ item.finished_at | formatTime }}
              </span>
            </div>
            <div class="col-5 col-sm">
              <font-awesome-icon
                :icon="['far', 'clock']"
                :style="{ color: 'rgba(0, 0, 0, 0.60)' }"
              />
              <span class="text-lg ml-1">
                {{
                  item.started_on_worker_at | differenceTime(item.finished_at)
                }}
              </span>
            </div>
            <div v-if="isAdmin" class="col">
              <icon-commit fillColor="rgba(0, 0, 0, 0.60)" :size="20" />
              <b-button
                variant="link"
                target="_blank"
                class="p-0"
                :href="item.commit_view_url"
              >
                <span class="text-lg">
                  {{ item.commit_hash | hashCommit }}
                </span>
              </b-button>
            </div>
          </div>
        </div>
        <div v-if="isAdmin" class="col-12 col-sm-6 d-flex pr-2">
          <font-awesome-icon
            class="mt-2 mt-sm-1"
            :icon="['fas', 'align-left']"
            :style="{ color: 'rgba(0, 0, 0, 0.60)' }"
          />
          <span class="ml-2 text-lg text-break">
            {{ item.commit_message }}
          </span>
        </div>
      </div>
      <div class="row py-2 no-gutters">
        <div class="col-12">
          <quill-editor
            v-if="isAdmin"
            v-model="item.description"
            :options="editorOption"
          />

          <p v-else v-html="item.description" />
        </div>
      </div>
      <div v-if="!isAdmin" class="row py-2 no-gutters justify-content-center">
        <div class="col-auto">
          <b-button size="lg" @click="$emit('push-route', item.slug)">
            Downloads Apps
          </b-button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import * as buildHelper from "@/services/build";
import { mapGetters } from "vuex";

export default {
  props: {
    item: {
      type: Object,
      require: true
    }
  },
  data() {
    return {
      editorOption: {
        placeholder: "Insert description...",
        theme: "snow",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            [{ header: 1 }, { header: 2 }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"]
          ]
        }
      }
    };
  },
  filters: {
    formatDate(date) {
      return buildHelper.getFormatDate(date);
    },
    formatTime(date) {
      return buildHelper.getFormatTime(date);
    },
    differenceTime(dateStart, dateEnd) {
      return buildHelper.differenceTime(dateStart, dateEnd);
    },
    hashCommit(commit) {
      if (commit) {
        return commit.substr(0, 7);
      }

      return commit;
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: "auth/isAuthorized"
    }),
    statusColor() {
      return buildHelper.getStatusColor(this.item.status);
    },
    statusIcon() {
      return buildHelper.getStatusIcon(this.item.status);
    },
    statusName() {
      return buildHelper.getStatusName(this.item.status);
    },
    branchColor() {
      if (["master", "develop"].includes(this.item.branch.toLowerCase())) {
        return buildHelper.getBranchColor(this.item.branch);
      }
      return buildHelper.getBranchColor("feature");
    }
  },
  methods: {
    onActived(event) {
      this.$emit("update", this.item.slug, { actived: event });
    },
    onUpdate() {
      this.$emit("update", this.item.slug, {
        description: this.item.description
      });
    }
  }
};
</script>
