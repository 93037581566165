import moment from "moment";

const SUCCESS = "1";
const FAILED = "2";
const ABORTED = "3";

const MASTER = "master";
const DEVELOP = "develop";
const FEATURE = "feature";

const STATUS_COLOR_MAP = {
  [SUCCESS]: "--success",
  [FAILED]: "--danger",
  [ABORTED]: "--warning"
};

const STATUS_NAME_MAP = {
  [SUCCESS]: "success",
  [FAILED]: "failed",
  [ABORTED]: "aborted"
};

const STATUS_ICON_MAP = {
  [SUCCESS]: ["fas", "check"],
  [FAILED]: ["fas", "times"],
  [ABORTED]: ["fas", "exclamation"]
};

const BRANCH_COLOR_MAP = {
  [MASTER]: "--cyan",
  [DEVELOP]: "--red",
  [FEATURE]: "--orange"
};

export const getStatusColor = status => {
  return STATUS_COLOR_MAP[status];
};

export const getStatusName = status => {
  return STATUS_NAME_MAP[status];
};

export const getStatusIcon = status => {
  return STATUS_ICON_MAP[status];
};

export const getFormatDate = date => {
  return moment(date).format("YY/MM/DD");
};

export const getFormatTime = date => {
  return moment(date).format("HH:mm:ss");
};

export const differenceTime = (dateStart, dateEnd) => {
  const duration = moment.utc(
    moment.duration(moment(dateEnd).diff(moment(dateStart))).asMilliseconds()
  );
  return `${duration.get("minutes")}m ${duration.get("seconds")}s`;
};

export const getBranchColor = branch => {
  return BRANCH_COLOR_MAP[branch];
};
